import { Permissions } from '@rabbit/data/types';

export const TenantClaimName = 'T';

// BE CAREFUL WHEN CHANGING THIS MAP! Any change here might break existing claims!
const PermissionToString: Map<Permissions, string> = new Map([
  // permissions from Notion https://www.notion.so/iwarranty/Security-a8c3b0832cc345a891457e3f3e780f0b?pvs=4
  [Permissions.TenantsView, 'A'],
  [Permissions.TenantsEdit, 'B'],
  [Permissions.UsersView, 'C'],
  [Permissions.UsersEdit, 'D'],
  [Permissions.UsersDelete, 'E'],
  [Permissions.CasesView, 'F'],
  [Permissions.CasesEdit, 'G'],
  [Permissions.DashboardView, 'H'],
  [Permissions.RegistrationsView, 'I'],
  [Permissions.RetailersView, 'J'],
  [Permissions.RetailersEdit, 'K'],
  [Permissions.RepairersView, 'L'],
  [Permissions.RepairersEdit, 'M'],
  [Permissions.RolesView, 'N'],
  [Permissions.RolesEdit, 'O'],
  [Permissions.ConsumersView, 'P'],
  [Permissions.ConsumersEdit, 'R'],
  [Permissions.Owner, 'S'],
  [Permissions.RegistrationsEdit, 'T'],
  [Permissions.WarrantyOffersView, 'U'],
  [Permissions.WarrantyOffersEdit, 'V'],
  [Permissions.VendablesDetailView, 'W'],
  [Permissions.VendablesDetailEdit, 'X'],
  // use any ASCII character for new permissions
  // add it to unit tests, so we can catch any changes to the map and create proper migration for production data
]);

// map each Permission to a single character and join them together to make a string
// e.g. [CasesView, CasesEdit] => 'FG'
export function Permissions2string(permissions: Permissions[]) {
  return permissions
    .map((sa) => {
      const result = PermissionToString.get(sa);
      if (result === undefined) {
        console.error(
          'Permissions2string: Unknown Permission. Update Permission2String map!',
          sa
        );
        throw new Error('Permissions2string: Unknown Permission!');
      }
      return result;
    })
    .join('');
}

// split a string made up of Permission characters into an array of Permission
// eg 'FG' => [CasesView, CasesEdit]
export function String2Permissions(permissionsString: string) {
  return permissionsString.split('').map((sa) => {
    const pair = [...PermissionToString].find(([key, value]) => sa === value);
    if (!pair) {
      console.error(
        'String2Permissions: Unknown string. Update Permission2String map!',
        sa
      );
      throw new Error('String2Permissions: Unknown permission string!');
    }
    return pair[0];
  });
}
