import { DTService } from '@rabbit/data/types';
import React, { useContext } from 'react';
import CardWrapperWithHeader from '../CardWrapperWithHeader/CardWrapperWithHeader';
import LabelledItemList, {
  LabelledItemShape,
} from '../../molecules/LabelledItemList/LabelledItemList';
import { getFormattedDate } from '../../utils/helpers';
import { AppContext } from '@rabbit/app-context';
import { LIST_CURRENCIES } from '@rabbit/bizproc/react';
import { useTranslation } from 'react-i18next';

export interface ServiceViewerProps {
  service: DTService | null;
}

const ServiceViewer = (props: ServiceViewerProps) => {
  const { service } = props;
  const { tenantInfo } = useContext(AppContext);
  const { t } = useTranslation();

  if (!service) return null;

  const formatServicePayload = (): LabelledItemShape[] => {
    const currencyCode = service.price?.currency || tenantInfo?.currency;
    const currency = LIST_CURRENCIES.find((item) => item.code === currencyCode);

    const price = service.price?.amount === 0 ? 'Free' : currency
      ? `${currency.code} ${currency.symbol} ${service.price?.amount}`
      : 'Free';

    return [
      { label: t('general.planId'), value: service.docid },
      { label: t('general.price'), value: price },
      { label: t('general.status'), value: service.status?.toUpperCase() },
      { label: t('general.term'), value: service.templateName },
      {
        label: t('general.startDate'),
        value: getFormattedDate(service.startDate),
      },
      {
        label: t('general.expireIn'),
        value: getFormattedDate(service.endDate),
      },
    ];
  };

  return (
    <CardWrapperWithHeader
      title="Roadside Assistance Plan"
      collapsedByDefault={false}
    >
      <LabelledItemList
        items={formatServicePayload()}
        itemProps={{
          labelClasses: 'font-nunito mb-[10px] text-sm text-gray-500',
          valueClasses: 'font-nunito font-medium text-[14px]',
        }}
      />
    </CardWrapperWithHeader>
  );
};

export default ServiceViewer;
