import Stripe from 'stripe';
import { FBF_CloudCallable } from '@rabbit/firebase/adapter';
import {
  ConsumerLink,
  CURRENCY_TYPES,
  DTPaymentRequest,
  DTPaymentTransaction,
  HoldingLink,
  MetaData,
  WarrantorLink,
} from '@rabbit/data/types';

export interface PaymentIntentResponsePayload extends Stripe.PaymentIntent {
  metadata: Record<string, any>;
}

export interface PaymentIntentBodyPayload {
  amount: number;
  currency: string;
  metadata?: Record<string, any>;
}

export interface PaymentRequestBodyPayload {
  holdingLink: HoldingLink;
  consumerLink: ConsumerLink;
  warrantorLink: WarrantorLink;
  amount: number;
  currency: CURRENCY_TYPES;
  description?: string;
  metadata: MetaData;
}

export interface PaymentRequestCancelBodyPayload {
  warrantorLink: WarrantorLink;
}

export interface CreatePaymentRequestResponse {
  data: DTPaymentRequest | string;
}

export interface CancelPaymentRequestResponse {
  data: string;
}

export interface GetPaymentRequestResponse {
  data: DTPaymentRequest[];
}

export interface PaginationMetaData {
  currentPage: number;
  previousPage: boolean;
  nextPage: boolean;
  totalPage: number;
  limit: number;
}

export interface GetPaymentTransaction {
  transactions: DTPaymentTransaction[];
  metadata: PaginationMetaData;
}

// Create a Payment intent for the stripe payment
export const nestApiCreatePaymentIntent = FBF_CloudCallable<
  PaymentIntentBodyPayload,
  { data: PaymentIntentResponsePayload }
>('api/payment-transaction/create-intent/|POST');

// Create a Payment request for the payment
export const nestApiCreatePaymenRequest = FBF_CloudCallable<
  PaymentRequestBodyPayload,
  { data: CreatePaymentRequestResponse }
>('api/payment-request/|POST');

// Create a Payment request for the payment
export const nestApiGetPaymenTransaction = FBF_CloudCallable<
  any,
  { data: GetPaymentTransaction }
>('api/payment-request/|POST');

// get all Consumer payment requests to pay
export const getAllServiceRequestsForConsumer = async (
  consumerLink: ConsumerLink
) => {
  try {
    // Define types for the response
    const result = await FBF_CloudCallable<
      PaymentRequestBodyPayload,
      DTPaymentRequest[]
    >(`api/payment-request?consumerLink=${consumerLink}|GET`)();
    return result.data; // Adjust based on the actual response structure
  } catch (error) {
    console.error('Error calling Cloud Function:', error);
    throw new Error('Failed to get service offers');
  }
};

// Cancel the Payment Request
export const cancelPaymentRequest = async (
  docid: string,
  payload: PaymentRequestCancelBodyPayload
) => {
  try {
    // Ensure the function correctly sends the payload in the request body
    const result = await FBF_CloudCallable<
      PaymentRequestCancelBodyPayload,
      CancelPaymentRequestResponse
    >(`api/payment-request/${docid}/cancel|PATCH`)(payload);

    return result.data; // Adjust based on the actual response structure
  } catch (error) {
    console.error('Error calling Cloud Function:', error);
    throw new Error('Failed to cancel payment request');
  }
};

// get all Consumer payment requests to pay
export const getAllTransaction = async (page: number, limit: number) => {
  try {
    // Define types for the response
    const result = await FBF_CloudCallable<any, GetPaymentTransaction>(
      `api/payment-transaction/get-all-transaction?page=${page}&limit=${limit}|GET`
    )();
    return result.data; // Adjust based on the actual response structure
  } catch (error) {
    console.error('Error calling Cloud Function:', error);
    throw new Error('Failed to get service offers');
  }
};
