import React from 'react';
import { AdditionalFields } from '@rabbit/helper';
import { CommonInputBuilder } from './CommonInputBuilder';
import { createDynamicYupSchema } from '@rabbit/app-context';

interface DynamicFormBuilderProps {
  fields: AdditionalFields;
}

const CommonFormBuilder: React.FC<DynamicFormBuilderProps> = ({ fields }) => {
  console.log(createDynamicYupSchema(fields).fields, 'CONSLING YUP SCHEMA');

  return (
    <div className="w-full space-y-5">
      {Object.entries(fields).map(([key, field]) => (
        <CommonInputBuilder field={field} key={key} name={key} />
      ))}
    </div>
  );
};

export default CommonFormBuilder;
