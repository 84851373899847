import {
  DTService,
  ServiceStatus,
} from '@rabbit/data/types';

export interface BLService {
  getLatestService: (
    services: DTService[] | null | undefined
  ) => DTService | undefined;
}

export const BL_Service: BLService = {
  getLatestService,
};

function getLatestService(
  services: DTService[] | null | undefined
): DTService | undefined {
  if (!services || services.length === 0) return undefined;

  const activeWarranties = services.filter(
    (service) => service.status === ServiceStatus.ACTIVE
  );

  console.log(services,activeWarranties,'CONSLINGACTIVESERVICE')

  if (activeWarranties.length === 0) {
    const sortedServices = services.sort(
      (a, b) => (b.startDate ?? 0) - (a.startDate ?? 0)
    );
    return sortedServices[0];
  } else if (activeWarranties.length > 1) {
    const sortedServices = activeWarranties.sort(
      (a, b) => (b.startDate ?? 0) - (a.startDate ?? 0)
    );
    return sortedServices[0];
  }

  return activeWarranties[0];
}
