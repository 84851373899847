import {
  DTConsumer_Private,
  DTFault,
  DTRepairer_Public,
  DTWarrantor_Public,
  FBD_Consumer_Private,
  FBD_Fault,
  FBD_Repairer_Public,
  FBD_Warrantor_Public,
  isValidPersonaId,
} from '@rabbit/data/types';

/**
 * General purpose hook for fetching data about one or more documents from a collection.
 */
export function useGetDocs() {
  /**
   * Fetches data about one or more repairers from the repairer_public collection.
   * @param {string[]} repairerIds
   * @returns An array of repairer data objects
   */
  async function getRepairerData(repairerIds: string[]) {
    const promises: Promise<DTRepairer_Public | null>[] = [];

    repairerIds.forEach((repairerId) => {
      if (!repairerId || !isValidPersonaId(repairerId)) return;
      if (!['R', 'N'].includes(repairerId[0])) return;
      promises.push(FBD_Repairer_Public.get(repairerId));
    });

    const repairerData = await Promise.all(promises);
    return repairerData;
  }

  // TODO: Will/should there be a Public DTConsumer?
  /**
   * Fetches data about one or more consumers from the consumer_private collection.
   * @param {string[]} consumerIds
   * @returns An array of consumer data objects
   */
  async function getConsumerData(consumerIds: string[]) {
    const promises: Promise<DTConsumer_Private | null>[] = [];

    consumerIds.forEach((consumerId) => {
      if (!consumerId || !isValidPersonaId(consumerId)) return;
      if (consumerId[0] !== 'C') return;
      promises.push(FBD_Consumer_Private.get(consumerId));
    });

    const consumerData = await Promise.all(promises);
    return consumerData;
  }

  async function getFaultData(faultIds: string[]) {
    const promises: Promise<DTFault | null>[] = [];

    faultIds.forEach((faultId) => {
      if (!faultId) return;

      promises.push(FBD_Fault.get(faultId));
    });

    const faultsData = await Promise.all(promises);
    return faultsData;
  }

  async function getWarrantorData(warrantorIds: string[]) {
    const promises: Promise<DTWarrantor_Public | null>[] = [];

    warrantorIds.forEach((warrantorId) => {
      if (!warrantorId) return;

      promises.push(FBD_Warrantor_Public.get(warrantorId));
    });

    const warrantorsData = await Promise.all(promises);
    return warrantorsData;
  }

  return { getConsumerData, getRepairerData, getFaultData, getWarrantorData };
}
