import React, { useContext } from 'react';
import { DTService_Offer_With_Template } from '@rabbit/data/types';
import { Button } from '@rabbit/elements/shared-components';
import { useTranslation } from 'react-i18next';
import { LIST_CURRENCIES } from '@rabbit/bizproc/react';
import { AppContext } from '@rabbit/app-context';

export interface ServicePlanCardProps {
  showButtonToPay?: boolean;
  plan: DTService_Offer_With_Template;
  onActivate: (plan: DTService_Offer_With_Template) => void;
  paymentStatus: string;
  disabled?: boolean;
}

const ServicePlanCard: React.FC<ServicePlanCardProps> = ({
  showButtonToPay = true,
  plan,
  onActivate,
  paymentStatus,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);

  const getFormattedPrice = (price: any) => {
    const currencyCode = price?.currency || tenantInfo?.currency;
    const currency = LIST_CURRENCIES.find((item) => item.code === currencyCode);
    return plan.price.amount === 0
      ? 'Free'
      : currency
      ? `${currency.code} ${currency.symbol} ${price.amount}`
      : 'Free';
  };

  return (
    <div
      className={`rounded-lg border border-gray-300 p-4 shadow-sm ${
        !plan.enabled ? 'cursor-not-allowed opacity-70' : ''
      }`}
    >
      <h3 className="text-lg font-semibold text-gray-800">{plan.title}</h3>
      <p className="mt-1 text-sm text-gray-600">{plan.template.description}</p>
      <p className="text-primary-900 mt-2 text-sm font-medium">
        Price: {getFormattedPrice(plan.price)}
      </p>
      {showButtonToPay && (
        <Button
          kind={paymentStatus === t('Cancel Payment') ? 'red' : 'primary'}
          className="mt-3 w-full rounded-md py-2 text-white transition"
          onClick={() => plan.enabled && onActivate(plan)}
          disabled={disabled}
        >
          {paymentStatus === t('Activated')
            ? paymentStatus
            : plan.price.amount === 0
            ? t('Free')
            : paymentStatus}
        </Button>
      )}
    </div>
  );
};

export default ServicePlanCard;
