import { useFormikContext } from 'formik';
import Toggle from '../../atoms/Toggle/Toggle';
import { useTranslation } from 'react-i18next';
import { FieldConfig } from '@rabbit/helper';
import { Input } from '../../atoms/inputs/Input/Input';

export interface DynamicInputBuilderProps {
  field: FieldConfig;
  name: string;
}

export const CommonInputBuilder: React.FC<DynamicInputBuilderProps> = ({
  field,
  name
}) => {
  const { t } = useTranslation();
  const { setFieldValue, getFieldProps } = useFormikContext();
  const props = getFieldProps(name)
  const baseStyle = `grid w-full grid-cols-1 gap-3`;

  const switchInput: Record<string, JSX.Element | null> = {
    radio: (
      <div className={`${baseStyle}`}>
        <label>
          {field.title} {field.validation?.required && '*'}
        </label>
        <div className="flex h-auto w-full flex-wrap items-center justify-start gap-4">
          {field.options?.map((value, i) => (
            <Input
              title={value}
              key={i}
              type="radio"
              name={name}
              settings={{
                radioValue: value, // Ensure value is dynamic
                radioLabel: value, // Translated label
              }}
              className="w-auto"
            />
          ))}
        </div>
      </div>
    ),
    string: (
      <div className={`${baseStyle}`}>
        <Input
          title={field.title}
          label={`${field.title} ${field.validation?.required ? '*' : ''}`}
          key={`${name}`} // Use unique keys for map items
          type="text"
          name={name}
          settings={{
            placeholder: field.decoration?.placeholder || '',
            ...(field.validation?.required && { hint: '*required' }),
          }}
          className="w-auto"
        />
      </div>
    ),
    'single-select': (
      <div className={`${baseStyle}`}>
        <Input
          type="select"
          name={name}
          label={`${field.title} ${field.validation?.required ? '*' : ''}`}
          settings={{
            isMulti: false,
            id: 'preferred_contact',
            placeholder: 'Please select',
            options: field.options?.map((value: string) => ({
              value: value,
              name: value,
              label: value,
            })),
          }}
        />
      </div>
    ),
    boolean: (
      <div className={`flex w-full items-center gap-2 rounded-lg border border-gray-200 bg-gray-100 px-3 py-2`}>
        <div className="grow">{t(field.title)}</div>
        <Toggle
          name={name}
          checked={(props.value as boolean) ?? false} // Ensure initialValue is used correctly
          onChange={() => {
            const props = getFieldProps(name);
            setFieldValue(name, !props.value);
          }}
        />
      </div>
    ),
  };

  return switchInput[field.type] ?? null;
};
