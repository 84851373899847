import { FBF_CloudCallable } from '@rabbit/firebase/adapter';

export const nestApiGetAllServiceOffers = async (query: string) => {
  try {
    // Define types for the response
    const result = await FBF_CloudCallable<any, any>(
      `api/service-offer?${query}|GET`
    )();
    return { data: result.data }; // Adjust based on the actual response structure
  } catch (error) {
    console.error('Error calling Cloud Function:', error);
    throw new Error('Failed to get service offers');
  }
};
