import { Dispatch, SetStateAction, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@rabbit/elements/shared-components';
import { DTConsumer_Private } from '@rabbit/data/types';
import { CUSTOMER_QUESTIONNAIRE } from '@rabbit/bizproc/react';
import { toast } from 'react-toastify';
import {
  DR_Consumer_Private,
  usePortalDocumentEditor,
} from '@rabbit/data/portal';
import { DRDocType } from '@rabbit/firebase/react';

const validationSchema = Yup.object({
  type_of_dwelling: Yup.string().trim(),
  living_situation: Yup.string().trim(),
  work_status: Yup.string().trim(),
  gender: Yup.string().trim(),
});

interface AdditionalInfoContainerProps {
  id: string;
  data: DTConsumer_Private;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

interface FormValuesShape {
  type_of_dwelling: string;
  living_situation: string;
  gender: string;
  work_status: string;
}

function AdditionalInfoContainer({
  id,
  data,
  setIsLoading,
}: AdditionalInfoContainerProps) {
  const [isSubmiting, setSubmiting] = useState(false);

  const { body, update, commit, isReady } = usePortalDocumentEditor(
    DR_Consumer_Private,
    id
  );

  const initialValues: FormValuesShape = {
    type_of_dwelling: body?.type_of_dwelling ?? '',
    living_situation: body?.living_situation ?? '',
    gender: body?.gender ?? '',
    work_status: body?.work_status ?? '',
  };

  const onSubmit = async (values: FormValuesShape) => {
    setSubmiting(true);
    setIsLoading(true);

    if (!body || !isReady) return;
    try {
      update({
        ...body,
        gender: values.gender,
        type_of_dwelling: values.type_of_dwelling,
        living_situation: values.living_situation,
        work_status: values.work_status,
      });

      await commit();
      toast.success('Details updated successfully!');
    } catch (error) {
      console.log('error', error);
    }
    setSubmiting(false);
    setIsLoading(false);
  };

  return (
    <div className="pt-[38px]">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ errors, values }) => (
          <Form className="font-nunito flex flex-col gap-4">
            {CUSTOMER_QUESTIONNAIRE.map((question, index) => {
              return (
                <div key={index}>
                  <div className="mb-2 font-semibold">{question.label}</div>
                  <div className="font-nunito flex flex-col gap-1">
                    {question.options.map((option, v) => {
                      return (
                        <Input
                          key={question.name + v}
                          type="radio"
                          name={question.name}
                          settings={{
                            radioLabel: option,
                            radioValue: option,
                            values: values,
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <Button
              kind="primary"
              type="submit"
              disabled={Object.keys(errors).length > 0 || isSubmiting}
              loading={isSubmiting}
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AdditionalInfoContainer;
