import { DTHoldingProxy } from '@rabbit/data/types';
import { FBF_CloudCallable } from '@rabbit/firebase/adapter';

export interface RegisterHoldingsServicePayload {
  templateLink: string;
  holdingLink: string;
  consumerLink: string;
  warrantorLink: string;
  price: {
    amount: number;
    currency: string;
  };
  tenantLink: string;
}

export interface RegisterHoldingsServiceResponsePayload {
  holdingProxy: DTHoldingProxy;
}

export const nestApiGetSingleHoldingProxy = FBF_CloudCallable<
  { holdingProxyLink: string },
  any
>('api/holdingProxies/holdingProxy/:holdingProxyLink|GET');

export const nestApiGetAllHoldingProxies = FBF_CloudCallable<any, any>(
  'api/holdingProxies/all/|GET'
);

export const nestApiRegisterInstallerHoldings = FBF_CloudCallable<any, any>(
  'api/holding/registerInstallerHolding/|POST'
);

export const nestApiRegisterHoldingsService = FBF_CloudCallable<
  RegisterHoldingsServicePayload,
  RegisterHoldingsServiceResponsePayload
>('api/holding/registerHoldingService/|POST');

export const nestApiRegisterHoldingsServiceByConsumer = FBF_CloudCallable<
  RegisterHoldingsServicePayload,
  RegisterHoldingsServiceResponsePayload
>('api/holding/registerHoldingServiceByConsumer/|POST');
