interface ValidationRules {
  required?: RequiredType;
  minValue?: number;
}

interface Decoration {
  placeholder?: string;
}

export interface FieldConfig {
  type: "string" | "radio" | "single-select" | "boolean" | "multi-select";
  title: string;
  options?: string[];
  initialValue?: string | number | boolean;
  validation?: ValidationRules;
  decoration?: Decoration;
  colSpan?: number;
}

export interface AdditionalFields {
  [key: string]: FieldConfig;
}

export enum RequiredType {
  REQUIRED_FOR_ALL = 'REQUIRED_FOR_ALL',
  REQUIRED_FOR_CONSUMER = 'REQUIRED_FOR_CONSUMER',
}

const AdditionalBaseConfig = {
  HOLDINGS: {
    NEW_REGISTRATION_FLOW: {
      ADDITIONAL_FIELDS: {
      } as AdditionalFields
    },
  },
};

export default AdditionalBaseConfig;