export * from './additional-config/base.js';

export const getAdditionalConfig = async (tenantLink: string) => {
  const fileName = tenantLink.toLowerCase() ?? 'base';

  try {
    return await import(`./additional-config/${fileName}.ts`);
  } catch (e) {
    console.warn(`File './additional-config/${fileName}.ts' not found`);
    return await import('./additional-config/base.js');
  }
};