import {
  SetStateAction,
  createContext,
  useEffect,
  useState,
  Dispatch,
} from 'react';
import * as Yup from 'yup';
import { LoadingSpinner } from '../elements/shared-components/src/index';
import { merge } from 'ts-deepmerge';
import BaseConfig from '../configs/src/base';
import { getAdditionalConfig, FieldConfig, RequiredType } from './helpers';
import React from 'react';
import { RCFN, RCOParamMap } from '../configs/src/utils/render-control';
import { subscribeDocument } from '../utils/api/api';
import { DTTenant_Public, TenantInfo } from '../data/types/src/index';
import AdditionalBaseConfig from './additional-config/base';

export type MergedConfigType = typeof BaseConfig &
  Partial<typeof AdditionalBaseConfig>;
export interface ConfigInterface {
  tenantInfo: DTTenant_Public['info'] | undefined;
  config: MergedConfigType;
  setConfig: Dispatch<SetStateAction<MergedConfigType>>;
  RenderControl: <K extends keyof RCOParamMap>(
    option: K,
    params: RCOParamMap[K]
  ) => boolean;
}

const AppContext = createContext<ConfigInterface>({
  tenantInfo: undefined,
  config: BaseConfig as MergedConfigType,
  setConfig: () => {},
  RenderControl: () => false,
});

type ConfigProviderWrapperProps = {
  children: React.ReactNode;
};

const AppContextProviderWrapper = ({
  children,
}: ConfigProviderWrapperProps) => {
  const [config, setConfig] = useState<MergedConfigType>(
    BaseConfig as MergedConfigType
  );
  const [isLoading, setIsLoading] = useState(true);
  // const isEmulator = import.meta.env.VITE_FIREBASE_MODE === 'EMULATOR';
  // const localConfig = localStorage.getItem('CONFIG');
  const [activeTenantLink, setActiveTenantLink] = useState(
    localStorage.getItem('activeTenant')
  );
  const [tenantInfo, setTenantInfo] = useState<TenantInfo>();

  // console.debug('activeTenantLink', activeTenantLink); // @TEST_REMOVE_LATER

  useEffect(() => {
    async function getTenantConfig(tenantLink: string) {
      const tenant = tenantLink.toLowerCase();
      try {
        return await import(`../configs/src/${tenant}.ts`);
      } catch (e) {
        console.warn(`File ${`../configs/src/${tenant}`} not found`);
        return BaseConfig;
      }
    }

    function updateActiveTenantLink() {
      console.debug('Active Tenant Link Updated');
      setActiveTenantLink(localStorage.getItem('activeTenant'));
    }

    window.addEventListener('activeTenantChange', updateActiveTenantLink);

    if (activeTenantLink) {
      console.debug('activeTenantLink', activeTenantLink); // @TEST_REMOVE_LATER
      void getTenantConfig(activeTenantLink).then((config) => {
        getAdditionalConfig(activeTenantLink).then((additionalConfig) => {
          const finalConfig = merge(
            BaseConfig,
            config.default as typeof BaseConfig,
            additionalConfig.default as typeof AdditionalBaseConfig
          );

          validateTenantConfig(finalConfig);
          console.log('finalConfig', finalConfig);
          setConfig(finalConfig as unknown as MergedConfigType);
          setIsLoading(false);
        });
      });

      const unSub = subscribeDocument<DTTenant_Public>(
        `tenant_public/${activeTenantLink}`,
        (doc) => {
          setTenantInfo(doc.data()?.info);
        }
      );

      // console.debug('config', config || '{}'); // @TEST_REMOVE_LATER
      return () => {
        window.removeEventListener(
          'activeTenantChange',
          updateActiveTenantLink
        );

        unSub();
      };
    }
  }, [activeTenantLink]);

  if (!activeTenantLink) return <LoadingSpinner size="md" />;

  const RenderControl: ConfigInterface['RenderControl'] = (option, params) => {
    return RCFN[option](config, params);
  };
  /* -------------------------------------------------------------------------- */
  /*                               Provider return                              */
  /* -------------------------------------------------------------------------- */
  if (!RenderControl || !setConfig || isLoading)
    return <LoadingSpinner size="lg" />;

  return (
    <AppContext.Provider
      value={{
        tenantInfo,
        config,
        setConfig,
        RenderControl,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

function getTenantConfigValidationSchema() {
  // Schema for additional field validation
  const additionalFieldSchema = Yup.object({
    type: Yup.mixed<'string' | 'radio' | 'single-select' | 'boolean'>()
      .oneOf(['string', 'radio', 'single-select', 'boolean'])
      .required('Type is required'),
    title: Yup.string().required('Title is required'),
    initialValue: Yup.mixed().test(
      'type-match',
      "Initial value type must match 'type'",
      function (value) {
        const type = this.parent.type;
        if (
          type === 'string' &&
          typeof value !== 'string' &&
          value !== undefined
        )
          return false;
        if (
          type === 'integer' &&
          typeof value !== 'number' &&
          value !== undefined
        )
          return false;
        return true;
      }
    ),
    validation: Yup.object({
      required: Yup.mixed<
        RequiredType.REQUIRED_FOR_ALL | RequiredType.REQUIRED_FOR_CONSUMER
      >().oneOf([
        RequiredType.REQUIRED_FOR_ALL,
        RequiredType.REQUIRED_FOR_CONSUMER,
      ]),
      minValue: Yup.number()
        .when('required', {
          is: true,
          then: (schema) => schema.min(0, 'minValue cannot be negative'),
        })
        .nullable(),
    }).nullable(),
    decoration: Yup.object({
      placeholder: Yup.string(),
    }).nullable(),
  });

  const configSchema = Yup.object().shape({
    HOLDINGS: Yup.object()
      .shape({
        NEW_REGISTRATION_FLOW: Yup.object()
          .shape({
            ADDITIONAL_FIELDS: Yup.object()
              .test(
                'validate-fields',
                'Invalid additional field configuration',
                function (fields) {
                  if (!fields) return true;
                  return Object.values(fields).every((field) =>
                    additionalFieldSchema.isValidSync(field)
                  );
                }
              )
              .nullable(),
          })
          .required(),
      })
      .required(),
  });

  return configSchema;
}

function createDynamicYupSchema(fields: { [key: string]: FieldConfig }) {
  const schema: Record<string, any> = {};

  Object.entries(fields).forEach(([key, field]) => {
    if (!field.validation?.required) {
      return; // Skip fields without required validation
    }

    const isRequiredForAll =
      field.validation.required === RequiredType.REQUIRED_FOR_ALL;
    const isRequiredForConsumer =
      field.validation.required === RequiredType.REQUIRED_FOR_CONSUMER;

    let baseValidation;
    switch (field.type) {
      case 'string':
      case 'radio':
      case 'single-select':
        baseValidation = Yup.string().trim(); // trim only needed for string types
        if (isRequiredForAll) {
          schema[key] = baseValidation.required(`${field.title} is required`);
        } else {
          schema[key] = baseValidation;
        }
        break;
      case 'boolean':
        schema[key] = Yup.boolean().optional();
        break;
      case 'multi-select':
        baseValidation = Yup.array();
        if (isRequiredForAll || isRequiredForConsumer) {
          baseValidation = baseValidation.min(
            1,
            `${field.title} requires at least one selection`
          );
        }
        schema[key] = baseValidation;
        break;
      default:
        schema[key] = Yup.mixed();
    }
  });

  return Yup.object().shape(schema);
}

const validateTenantConfig = (config: any) => {
  const configSchema = getTenantConfigValidationSchema();

  try {
    configSchema.validateSync(config, { abortEarly: false });
  } catch (err: any) {
    console.error('Invalid configuration:', err.errors);
    throw new Error('Invalid configuration');
  }
};

export { AppContext, AppContextProviderWrapper, createDynamicYupSchema };
