'use client';

import React, { createContext, useContext, ReactNode, useState, Dispatch } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { AppContext } from '@rabbit/app-context';
import { LIST_CURRENCIES } from '../../shared/consts';
import {
  nestApiCreatePaymentIntent,
} from '@rabbit/bizproc/core';
import { MetaData } from '@rabbit/data/types';

interface StripeProviderProps {
  children: ReactNode;
}

export interface PaymentIntentPayload {
  amount: number;
  currency: string;
  metadata: MetaData;
}

interface StripeContextType {
  processPayment: (stripe: Stripe | null, elements: any) => Promise<ProcessPaymentResult>;
  isLoading: boolean;
  error: string | null;
  paymentIntentPayload: PaymentIntentPayload;
  setPaymentIntentPayload: Dispatch<React.SetStateAction<PaymentIntentPayload>>;
}

interface ProcessPaymentResult {
  success: boolean;
  data?: any;
  error?: string;
}

const StripeContext = createContext<StripeContextType | undefined>(undefined);

export const useStripeContext = (): StripeContextType => {
  const context = useContext(StripeContext);
  if (!context) {
    throw new Error('useStripeContext must be used within a StripePaymentProvider');
  }
  return context;
};

export const StripePaymentProvider: React.FC<StripeProviderProps> = ({ children }) => {
  const { tenantInfo } = useContext(AppContext);
  const stripePromise = loadStripe(
    tenantInfo?.stripePublishKey ?? import.meta.env.VITE_STRIPE_PUBLISH_KEY
  );

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [paymentIntentPayload, setPaymentIntentPayload] = useState<PaymentIntentPayload>({
    amount: 1,
    currency: tenantInfo?.currency?.toLowerCase() || 'usd', // Convert currency to lowercase
    metadata: {} as MetaData,
  });

  const findCurrency = LIST_CURRENCIES.find(
    (items) => items.code.toLowerCase() === paymentIntentPayload.currency // Compare in lowercase
  );

  const processPayment = async (stripe: Stripe | null, elements: any): Promise<ProcessPaymentResult> => {
    setIsLoading(true);
    setError(null);

    try {
      const adjustedAmount = Math.round(paymentIntentPayload.amount * (findCurrency?.smallestUnit || 100));
      const response = await nestApiCreatePaymentIntent({
        amount: adjustedAmount,
        currency: paymentIntentPayload.currency.toLowerCase(), // Convert currency to lowercase
        metadata: paymentIntentPayload.metadata,
      });

      const paymentIntent: any = response.data;

      const submitResult = await elements.submit();
      if (submitResult.error) throw new Error(submitResult.error.message);

      if (stripe && paymentIntent) {
        const stripeData = await stripe.confirmPayment({
          elements,
          clientSecret: paymentIntent.client_secret,
          confirmParams: { return_url: 'http://localhost:3000/payment-success' },
          redirect: 'if_required',
        });

        if (stripeData.error) {
          toast.error(t('Payment confirmation failed'));
          throw new Error(stripeData.error.message);
        }
        return { success: true, data: stripeData.paymentIntent };
      }
    } catch (err: any) {
      console.error('Payment Error:', err);
      toast.error(t('Something went wrong during payment processing'));
      return { success: false, error: err.message || 'Unknown error' };
    } finally {
      setIsLoading(false);
    }

    return { success: false, error: 'An unknown error occurred' };
  };

  const options: StripeElementsOptions = {
    mode: 'payment',
    amount: Math.round(paymentIntentPayload.amount * (findCurrency?.smallestUnit || 100)),
    currency: paymentIntentPayload.currency.toLowerCase(),
  };

  return (
    <StripeContext.Provider
      value={{
        processPayment,
        isLoading,
        error,
        paymentIntentPayload,
        setPaymentIntentPayload,
      }}
    >
      <Elements stripe={stripePromise} options={options}>
        {children}
      </Elements>
    </StripeContext.Provider>
  );
};
