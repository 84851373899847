import { Table } from '@rabbit/elements/shared-components';
import { MRT_ColumnDef, MRT_TableOptions } from 'material-react-table';
import { ReactElement } from 'react';
import { formatDate, isValidDate, titleCase } from './helpers';

export type TableFields<T> = ({
  header: string;
  type?: 'Date' | 'Status';
  value: (arg0: T) => any;
  primary?: boolean;
  Header?: ReactElement;
} & MRT_ColumnDef<any>)[];

interface TableProps<T> extends Partial<MRT_TableOptions<any>> {
  className?: string;
  onClickRow?: (data: T) => any;
  advancedTable?: boolean;
}

export function CreateTable<T>(
  sourceData: T[],
  tableFields: TableFields<T>
): (props: TableProps<T>) => ReactElement<any, any> {
  const name = tableFields
    .map((field) => (field.header || ' ')[0])
    .join('')
    .replace(' ', '');

  const columns: MRT_ColumnDef<any>[] = tableFields.map((field) => {
    const id = field.header.replace(/ /, '_').toLowerCase();
    field.id = id.trim() !== '' ? id : field.accessorKey || 'id';
    const fieldFormat = {
      ...field,
      accessorKey: field.id,
      size: field.size ? field.size : field.primary ? 180 : 20,
    };
    if (field.type === 'Date') {
      fieldFormat.Cell = ({ cell }: any) => {
        if (cell.getValue()) {
          if (isValidDate(new Date(cell.getValue()))) {
            return formatDate(new Date(cell.getValue()));
          }
        }
        return '-';
      };
    }
    return fieldFormat;
  });

  const record: any[] = [];
  sourceData.map((data) => {
    const row: any = {};
    tableFields.forEach((field) => {
      var value: any;
      if (field.type === 'Status') {
        const status = field.value(data);
        value = parseStatus(status);
      } else value = field.value(data);
      row[field.id as string] = value;
    });
    record.push(row);
  });

  return (props: TableProps<T>) => (
    <div className="relative z-0 w-full">
      <Table
        {...props}
        name={name}
        columns={columns}
        data={record}
        onSelectRow={(index: number) =>
          props.onClickRow && props.onClickRow(sourceData[index])
        }
      />
    </div>
  );
}

const parseStatus = (status: string) => {
  const red = [
    'deleted',
    'inactive',
    'no',
    'voided',
    'expired',
    'outdated',
    'suspended',
    'rejected',
  ];
  const orange = ['pending', 'ongoing', 'in review', 'unsubmitted'];
  const green = ['active', 'yes', 'published', 'approved', 'accepted'];
  const style =
    red.indexOf(status.toLowerCase()) > -1
      ? 'bg-red-100 text-red-800'
      : orange.indexOf(status.toLowerCase()) > -1
      ? 'bg-orange-100 text-orange-800'
      : green.indexOf(status.toLowerCase()) > -1
      ? 'bg-green-100 text-green-800'
      : 'bg-gray-300 text-gray-900 font-nunito';
  return (
    <div className={`inline-flex rounded-md px-3 py-[2px] ${style}`}>
      {status ? titleCase(status) : '-'}
    </div>
  );
};
