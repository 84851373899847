import {
  ConsumerLink,
  CURRENCY_TYPES,
  DTConsumer_Private,
  DTHoldingProxy,
  DTPaymentRequest,
  DTService_Offer_With_Template,
  PersonaTypeSingleLetter,
  PrincipalsFieldName,
  TenantLink,
} from '@rabbit/data/types';
import {
  BL_Service,
  getAllServiceRequestsForConsumer,
  nestApiGetAllServiceOffers,
  nestApiRegisterHoldingsServiceByConsumer,
  RegisterHoldingsServicePayload,
} from '@rabbit/bizproc/core';
import {
  Button,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import { useTranslation } from 'react-i18next';
import { AppContext } from '@rabbit/app-context';
import { useContext, useEffect, useState } from 'react';
import { getRootPersonaFromLexicon } from '@rabbit/bizproc/client';
import { useGetConsumerData, useStripeContext } from '@rabbit/bizproc/react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import ServicePlanCard from 'libs/elements/shared-components/src/lib/molecules/ServicePlanCard/ServicePlanCard';
import { toast } from 'react-toastify';

export interface ModalAddNewServiceProps {
  handleClose: () => void;
  holdingId: string;
  consumerLink: string;
  tenantLink: TenantLink;
  holdingProxy?: DTHoldingProxy;
  consumePrivate?: DTConsumer_Private;
  shouldRefresh?: () => void;
  requestedService?: DTPaymentRequest;
}

const ModalAddNewService = ({
  handleClose,
  tenantLink,
  holdingId,
  consumerLink,
  holdingProxy,
  consumePrivate,
  shouldRefresh,
  requestedService,
}: ModalAddNewServiceProps) => {
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);
  const stripe: Stripe | null = useStripe();
  const elements = useElements();
  const {
    processPayment,
    paymentIntentPayload,
    setPaymentIntentPayload,
    isLoading: isPaymentProcess,
  } = useStripeContext();

  const [availableOffers, setAvailableOffers] = useState<
    DTService_Offer_With_Template[] | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] =
    useState<DTService_Offer_With_Template | null>(
      requestedService
        ? ({
            ...requestedService.metadata.offerSnapshot,
            template: requestedService.metadata.templateSnapshot,
          } as DTService_Offer_With_Template)
        : null
    );
  const [allPaymentRequests, setAllPaymentRequests] = useState<
    DTPaymentRequest[]
  >([]);

  const { holding_proxy, consumer_private } =
    holdingProxy || consumePrivate
      ? { holding_proxy: holdingProxy, consumer_private: consumePrivate }
      : useGetConsumerData(holdingId) ?? {};

  const activeServicePlan = BL_Service.getLatestService(
    holding_proxy?.services
  );

  // Fetch available service offers
  const fetchServiceOffers = async () => {
    setLoading(true);
    try {
      const res = await nestApiGetAllServiceOffers(`tenantLink=${tenantLink}`);
      setAvailableOffers(res?.data ?? []);
    } catch (error) {
      console.error('Error fetching service offers:', error);
    }
    setLoading(false);
  };

  // Handle plan activation
  const handleActivatePlan = (plan: DTService_Offer_With_Template) => {
    setSelectedPlan(plan);
    if (plan.price.amount === 0) {
      addFreeServicePlan(plan);
    } else {
      setPaymentIntentPayload({
        amount: plan.price.amount,
        currency: plan.price.currency,
        metadata: {
          templateLink: plan.templateLink,
          holdingLink: holdingId,
          consumerLink,
          tenantLink,
          offerLink: plan.docid,
          warrantorLink: getRootPersonaFromLexicon(
            t(PrincipalsFieldName),
            PersonaTypeSingleLetter.Warrantor
          ),
        },
      });
    }
  };

  const addFreeServicePlan = async (plan: DTService_Offer_With_Template) => {
    console.log('plan 2', plan);
    const payload: RegisterHoldingsServicePayload = {
      holdingLink: holdingId,
      consumerLink: consumerLink,
      warrantorLink: getRootPersonaFromLexicon(
        t(PrincipalsFieldName),
        PersonaTypeSingleLetter.Warrantor
      ),
      price: {
        amount: 0,
        currency: tenantInfo?.currency as CURRENCY_TYPES,
      },
      tenantLink: tenantLink,
      templateLink: plan.templateLink,
    };
    const response = await nestApiRegisterHoldingsServiceByConsumer(payload);
    if (response.data.holdingProxy) {
      toast.success(t('Service plan added successfully'));
      handleClose();
    }
  };

  const handlePaymentProcess = async () => {
    if (!selectedPlan) return;
    if (selectedPlan.price.amount === 0) {
      addFreeServicePlan(selectedPlan);
    } else {
      const paymentResponse = await processPayment(stripe, elements);
      if (paymentResponse.success) {
        // added a timeout to ensure a slight delay, allowing the webhook process to complete successfully.
        setTimeout(() => {
          toast.success(t('Payment processed successfully!'));
          shouldRefresh && shouldRefresh();
          handleClose();
        }, 3000);
      }
    }
  };

  useEffect(() => {
    if (tenantLink) fetchServiceOffers();
  }, [tenantLink]);

  useEffect(() => {
    if (selectedPlan && selectedPlan.price.amount !== 0) {
      setPaymentIntentPayload({
        amount: selectedPlan.price.amount,
        currency: selectedPlan.price.currency,
        metadata: {
          templateLink: selectedPlan.templateLink,
          holdingLink: holdingId,
          consumerLink,
          tenantLink,
          offerLink: selectedPlan.docid,
          warrantorLink: getRootPersonaFromLexicon(
            t(PrincipalsFieldName),
            PersonaTypeSingleLetter.Warrantor
          ),
          requestId: requestedService?.docid,
        },
      });
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (consumer_private) {
      const getServiceRequests = async () => {
        const response: DTPaymentRequest[] =
          await getAllServiceRequestsForConsumer(consumerLink as string);
        if (response.length > 0) {
          setAllPaymentRequests(response);
        }
      };

      getServiceRequests();
    }
  }, [consumer_private]);

  return (
    <Modal
      className="relative w-[450px]"
      kind="generic"
      settings={{
        title: selectedPlan
          ? t('general.pay_for_this_service')
          : t('general.selectRoadsideAssistancePlan'),
        handleClose,
      }}
    >
      {loading && (
        <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center rounded-md bg-white/50">
          <LoadingSpinner size="md" />
        </div>
      )}
      <div className="w-full space-y-4 p-4">
        {/* List Available Plans */}
        {!selectedPlan && availableOffers?.length ? (
          availableOffers.map((plan) => {
            const hasPendingPayment = allPaymentRequests.some(
              (request) => request.metadata.templateLink === plan.templateLink
            );
            return (
              <ServicePlanCard
                key={plan.docid}
                plan={plan}
                paymentStatus={
                  hasPendingPayment
                    ? t('Requested for payment')
                    : activeServicePlan?.templateLink === plan.templateLink
                    ? t('Activated')
                    : t('Activate Service')
                }
                disabled={activeServicePlan?.templateLink === plan.templateLink}
                onActivate={handleActivatePlan}
              />
            );
          })
        ) : selectedPlan && selectedPlan.price.amount !== 0 ? (
          <>
            <ServicePlanCard
              showButtonToPay={false}
              plan={selectedPlan}
              onActivate={() => {}}
              paymentStatus=""
            />
            {selectedPlan.price.amount !== 0 && (
              <PaymentElement
                options={{
                  layout: {
                    type: 'tabs',
                    defaultCollapsed: false,
                    radios: true,
                    spacedAccordionItems: false,
                  },
                }}
              />
            )}
            <Button
              kind="primary"
              disabled={isPaymentProcess}
              className="w-full"
              onClick={handlePaymentProcess}
            >
              {`${t('Pay')} ${paymentIntentPayload.currency.toUpperCase()} ${
                paymentIntentPayload.amount
              }`}
            </Button>
          </>
        ) : (
          <p>{t('general.no_offers_available')}</p>
        )}
      </div>
    </Modal>
  );
};

export default ModalAddNewService;
