import classNames from 'classnames';
import { Field, useField } from 'formik';
import { InputSettingsShape } from '@rabbit/elements/shared-types';
import { useRef, useState } from 'react';

type InputTypeTimeProps = {
  name: string;
  settings: InputSettingsShape;
};

const dynamicStyles = {
  base: 'font-nunito bg-gray-50 text-base text-gray-500 border-[1px] border-gray-300 rounded-lg focus:border-primary-600 w-full px-4 py-3 placeholder-gray-400 !shadow-none !outline-none !ring-0',
  error: 'bg-red-50 border-red-500 text-red-700',
  disabled: 'border-gray-300 text-gray-400 opacity-60',
};

function StyledInput({ field, ...props }: any) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [display, setDisplay] = useState('00:00');

  const formatDisplay = (value: string) => {
    let nums = value.replace(/[^0-9]/g, '').replace(':', '');
    let hours = nums.slice(0, 2) || '00';
    let minutes = nums.slice(2, 4) || '00';
    if (Number(minutes) > 59) {
      minutes = '59';
      nums = nums.slice(2, 4) + '59';
    }
    setDisplay(`${hours}:${minutes}`);
    if (nums.length > 2) nums = nums.slice(0, 2) + ':' + nums.slice(2, 4);
    inputRef.current!.value = nums.slice(0, 5);
  };

  return (
    <div
      className={'relative ' + props.className}
      onClick={() => inputRef.current!.focus()}
    >
      <input
        ref={inputRef}
        type={'text'}
        id={props.id}
        onInput={(e: any) => {
          formatDisplay(e.target.value);
        }}
        {...props}
        {...field}
        className={
          'absolute left-0 top-0 w-[100px] !border-0 !bg-transparent text-transparent caret-gray-900 ' +
          props.className
        }
      />
      <span
        className={
          'font-nunito relative bottom-0 !border-0 !bg-transparent text-base '
        }
      >
        {display}
      </span>
    </div>
  );
}

export function InputTypeTime({ name, settings }: InputTypeTimeProps) {
  const { disabled, id, placeholder, ...rest } = settings;
  const [, meta] = useField(name);

  return (
    <Field
      name={name}
      component={StyledInput}
      className={classNames(dynamicStyles.base, {
        [`${dynamicStyles.error}`]: meta.error,
        [`${dynamicStyles.disabled}`]: disabled,
      })}
      type={'text'}
      id={id}
      {...rest}
    />
  );
}
