import {
  DesktopContainer,
  LoadingSpinner,
  getCurrencyFormat,
} from '@rabbit/elements/shared-components';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useMemo, useContext } from 'react';
import {
  getAllTransaction,
  GetPaymentTransaction,
  PaginationMetaData,
} from '@rabbit/bizproc/core';
import { DTPaymentTransaction, TransactionStatus } from '@rabbit/data/types';
import { CreateTable } from '@rabbit/sage/utils/CreateTable';
import { useTranslation } from 'react-i18next';
import { ReceiptPercentIcon } from '@heroicons/react/24/outline';
import { AppContext } from '@rabbit/app-context';
import { LIST_CURRENCIES } from '@rabbit/bizproc/react';

function AccountTransactions() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);

  const [transactions, setTransactions] = useState<DTPaymentTransaction[]>([]);
  const [metadata, setMetaData] = useState<PaginationMetaData>();
  const [loading, setLoading] = useState(false);

  // Fetch Consumer Transactions
  const getConsumerTransaction = async () => {
    try {
      setLoading(true);
      const response: GetPaymentTransaction = await getAllTransaction(1, 10);
      setTransactions(response.transactions);
      setMetaData(response.metadata);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getConsumerTransaction();
  }, []);

  // Table Columns
  const TransactionTable = useMemo(
    () =>
      CreateTable<DTPaymentTransaction>(transactions, [
        {
          header: t('Plan'),
          value: (tx) => tx.metadata.templateSnapshot?.title,
          size: 220,
        },
        {
          header: t('general.amount'),
          value: (tx) => {
            const currencyCode = tx.currency
              ? tx.currency
              : tenantInfo?.currency;
            const currency = LIST_CURRENCIES.find(
              (item) => item.code === currencyCode
            );
            return getCurrencyFormat(
              tx.amount / (currency?.smallestUnit || 100),
              tx.currency
            );
          },
          size: 100,
        },
        {
          header: t('general.status'),
          type: 'Status',
          value: (tx) => t(tx.status || TransactionStatus.PENDING),
        },
        {
          header: t('general.date'),
          type: 'Date',
          value: (tx) => tx.transactionDate,
        },
        {
          header: t('general.reciept'),
          size: 50,
          value: (tx) => tx.receiptUrl,
          Cell: ({ row }) => {
            console.log('ASDFGHJKL', row.index);
            const transactionData = transactions[row.index];
            return (
              <div className="flex justify-center items-center">
                <ReceiptPercentIcon
                  className="text-primary-900 h-5 w-5 cursor-pointer"
                  onClick={() =>
                    window.open(transactionData.receiptUrl, '_blank')
                  }
                />
              </div>
            );
          },
        },
      ]),
    [transactions, t]
  );

  return (
    <DesktopContainer
      title="Transactions"
      goBack={() => navigate(-1)}
      className="min-w-full"
    >
      {loading && <LoadingSpinner size="md" overlay />}
      {transactions.length === 0 && !loading && (
        <p className="p-5">{t('No transactions found')}</p>
      )}
      {transactions.length > 0 && (
        <TransactionTable
          enablePagination={transactions.length > 10}
          initialState={{ showGlobalFilter: true }}
          muiSearchTextFieldProps={{ sx: { display: 'none' } }}
          muiTopToolbarProps={{ sx: { display: 'none' } }}
          muiTableHeadCellProps={{
            className: 'relative bg-gray-200 uppercase font-light',
          }}
          muiTableBodyCellProps={{ className: 'px-4 py-2' }}
        />
      )}
    </DesktopContainer>
  );
}

export default AccountTransactions;
