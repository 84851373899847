import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { DTHoldingProxy } from '@rabbit/data/types';
import LabelledItemList from '../LabelledItemList/LabelledItemList';
import { ConsumerHoldingSummaryShape } from '@rabbit/bizproc/client';
import CardWrapperWithHeader from '../CardWrapperWithHeader/CardWrapperWithHeader';

export interface AdditionalDetailsViewProps {
  holdingProxy?: DTHoldingProxy | ConsumerHoldingSummaryShape | null;
  title: string;
}

export interface FormattedStateProps {
  label: string;
  value: string;
}

function formatKey(key: string) {
  // Create a new key by inserting a space before each uppercase letter and capitalizing each word
  return (
    key
      // Insert a space before each uppercase letter
      .replace(/([A-Z])/g, ' $1')
      // Trim spaces and capitalize the first letter of each word
      .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())
  );
}

function transformKeys(data: { [key: string]: any }) {
  const newData: { [key: string]: any } = {};
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const formattedKey = formatKey(key);
      newData[formattedKey] = data[key];
    }
  }
  return newData;
}

const AdditionalDetailsView = ({
  holdingProxy,
  title,
}: AdditionalDetailsViewProps) => {
  const [formatedState, setFormattedState] = useState<FormattedStateProps[]>(
    []
  );

  useEffect(() => {
    if (holdingProxy) {
      const transformedAdditionalData = transformKeys(
        holdingProxy.additionalData || {}
      );
      const formattedData = Object.entries(transformedAdditionalData).map(
        ([label, value]) => ({
          label,
          value: value?.toString() || '-',
        })
      );

      setFormattedState(formattedData);
      console.log(holdingProxy, 'CONSLING HOLDING PROXIES');
    }
  }, [holdingProxy]);

  return (
    <CardWrapperWithHeader title={title}>
      {!holdingProxy && <RenderLoadingState />}
      {formatedState.length > 0 && <LabelledItemList items={formatedState} />}
    </CardWrapperWithHeader>
  );
};

const RenderLoadingState = () => (
  <div className="flex flex-col gap-6 p-5">
    <div className="grid grid-cols-2 gap-5 md:grid-cols-6">
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
    </div>
    <div className="grid grid-cols-2 gap-5 md:grid-cols-6">
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
    </div>
  </div>
);

export default AdditionalDetailsView;
