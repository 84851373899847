import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
  Z_NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { DateTime, Z_Money } from '../base/basic';
import { z, schema, OurDateTime } from '@rabbit/utils/ts';
import {
  DTService_Template,
  Z_DTService_Template,
  ServiceTemplateLink,
  Z_ServiceTemplateLink,
} from './service-template';
import {
  DTService_Offer,
  ServiceOfferLink,
  Z_DTService_Offer,
} from './service-offer';
import {
  ConsumerLink,
  Z_ConsumerLink,
  RetailerLink,
  Z_RetailerLink,
  WarrantorLink,
  Z_WarrantorLink,
} from '../persona';
import { TenantLink, Z_TenantLink } from './tenant';
import { HoldingLink, Z_HoldingLink } from './holding';
import { IncomingHttpHeaders } from 'http';

/** Enum representing the possible statuses of a payment request */
export enum PaymentRequestStatus {
  PENDING = 'pending',
  PAID = 'paid',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
  REQUESTED = 'requested'
}

export enum CURRENCY_TYPES {
  AUD = 'AUD',
  GBP = 'GBP',
  CAD = 'CAD',
  EUR = 'EUR',
  USD = 'USD',
}

export const EXPIRE_IN = 5 * 24 * 60 * 60 * 1000; // 5 days in milliseconds

/**
 * Metadata containing essential data required for processing payments,
 * ensuring accurate tracking in the webhook triggered by Stripe,
 * and facilitating the assignment of the requested service to the consumer.
 */
export interface MetaData {
  /** The holding entity associated with this payment request */
  holdingLink?: HoldingLink;

  /** The tenant linked to this payment request */
  tenantLink?: TenantLink;

  /** The consumer (end-user) for whom this payment request is generated */
  consumerLink: ConsumerLink;

  /** The warrantor responsible for handling service claims */
  warrantorLink: WarrantorLink;

  /** The retailer who requested and registered this service */
  retailerLink?: RetailerLink;

  /** The service template linked to this payment request */
  templateLink?: ServiceTemplateLink;

  /** A snapshot of the service template at the time of request creation */
  templateSnapshot?: DTService_Template;

  /** The service offer linked to this payment request */
  offerLink?: ServiceOfferLink;

  /** A snapshot of the service offer as it was when the request was created */
  offerSnapshot?: DTService_Offer;

  requestId?: string;

  headers?:IncomingHttpHeaders
}

/** Represents a payment request in the system */
export interface DTPaymentRequest extends NoSqlDoc {
  /** The holding entity associated with this payment request */
  holdingLink: HoldingLink;

  /** The consumer this service instance is associated with */
  consumerLink: ConsumerLink;

  /** The warrantor responsible for handling this service and any associated claims */
  warrantorLink: WarrantorLink;

  /** The retailer who requested this service and registered it */
  retailerLink?: RetailerLink;

  /** The amount to be paid (in cents) */
  amount: number;

  /** The currency of the payment (e.g., USD, EUR) */
  currency: CURRENCY_TYPES;

  /** The current status of the payment request */
  status: PaymentRequestStatus;

  /** The timestamp when the payment request was created */
  requestDate: number;

  /** The timestamp when the payment request will expire */
  expiryDate: number;

  /** A description providing additional details about the payment request */
  description?: string;

  /**
   * Metadata containing essential data required for processing payments,
   * ensuring accurate tracking in the webhook triggered by Stripe,
   * and facilitating the assignment of the requested service to the consumer.
   */
  metadata: MetaData;

  /** The identifier of the last user who updated this request */
  updatedBy: string;

  /** The timestamp when the payment request was registered */
  registeredTime: DateTime;
}

/** Firestore collection configuration for PaymentRequest */
export const FBD_PaymentRequest = MakeFBDocType<DTPaymentRequest>({
  name: 'PaymentRequest',
  collection: 'payment_requests',
  empty: () => {
    const result: DTPaymentRequest = {
      ...Empty_NoSqlDoc(),
      holdingLink: '',
      consumerLink: '',
      warrantorLink: '',
      retailerLink: '',
      amount: 0,
      currency: CURRENCY_TYPES.USD,
      status: PaymentRequestStatus.PENDING,
      requestDate: OurDateTime.nowUTCTimestamp(),
      expiryDate: OurDateTime.nowUTCTimestamp() * EXPIRE_IN,
      metadata: {
        holdingLink: '',
        tenantLink: '',
        consumerLink: '',
        warrantorLink: '',
        retailerLink: '',
        templateLink: '',
        templateSnapshot: {} as DTService_Template,
        offerLink: '',
        offerSnapshot: {} as DTService_Offer,
      },
      updatedBy: '',
      registeredTime: OurDateTime.nowUTCTimestamp(),
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});

/** Zod schema for PaymentRequestStatus enum */
export const Z_PaymentStatus = z.nativeEnum(PaymentRequestStatus);

/** Zod schema for validating PaymentRequest data */
export const Z_DTPaymentRequest = schema<DTPaymentRequest>()(
  z
    .object({
      holdingLink: Z_HoldingLink,
      consumerLink: Z_ConsumerLink,
      warrantorLink: Z_WarrantorLink,
      retailerLink: Z_RetailerLink.optional(),
      amount: z.number(),
      currency: z.string(),
      status: Z_PaymentStatus,
      requestDate: z.number().default(OurDateTime.nowUTCTimestamp()),
      expiryDate: z.number().optional(),
      description: z.string().optional(),
      metadata: z.object({
        holdingLink: Z_HoldingLink.optional(),
        tenantLink: Z_TenantLink.optional(),
        consumerLink: Z_ConsumerLink,
        warrantorLink: Z_WarrantorLink,
        retailerLink: Z_RetailerLink.optional(),
        templateLink: Z_ServiceTemplateLink.optional(),
        templateSnapshot: Z_DTService_Template.optional(),
        offerLink: Z_DTService_Offer.optional(),
        offerSnapshot: z.any().optional(),
      }),
      updatedBy: z.string(),
      registeredTime: z.string().default(new Date().toISOString()),
    })
    .merge(Z_NoSqlDoc) // Inherits NoSqlDoc properties
);
