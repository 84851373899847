import { ConsumerLink, Z_ConsumerLink, Z_RetailerLink, Z_WarrantorLink } from '../persona';
import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
  Z_NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { Z_TenantLink } from './tenant';
import { Z_HoldingLink } from './holding';
import { CURRENCY_TYPES, MetaData } from './payment-request';
import { z, schema, OurDateTime } from '@rabbit/utils/ts';

/** Enum for Stripe transaction status */
export enum TransactionStatus {
  SUCCEEDED = 'succeeded',
  PAID = 'paid',
  FAILED = 'failed',
  REFUNDED = 'refunded',
  PENDING = 'pending', // Added pending status
  REQUESTED = 'requested'
}

export enum PaymentProvider {
  STRIPE = 'stripe',
}

/** Represents a transaction linked to a payment request */
export interface DTPaymentTransaction extends NoSqlDoc {
  /** Unique identifier for the Stripe Payment Intent */
  consumerLink: ConsumerLink;

  /** Unique identifier for the Stripe Payment Intent */
  paymentIntentId: string;

  /** Unique identifier for the Stripe Charge */
  chargeId?: string;

  /** The Stripe balance transaction ID */
  balanceTransactionId?: string;

  /** Amount paid in cents */
  amount: number;

  /** Payment currency (e.g., USD, EUR) */
  currency: CURRENCY_TYPES;

  /** Transaction status (e.g., succeeded, failed, refunded) */
  status: TransactionStatus;

  /** Stripe receipt URL */
  receiptUrl?: string;

  /** Stripe Payment Method ID */
  paymentMethodId?: string;

  /** Indicates whether the charge was captured */
  captured?: boolean;

  /** Stripe failure code in case of failed payments */
  failureCode?: string;

  /** Failure message in case of failed payments */
  failureMessage?: string;

  /** Timestamp when the transaction was completed */
  transactionDate: number;

  /** Payment provider (useful for future multi-provider support) */
  paymentProvider: 'stripe';

  /** Metadata used for post-payment service handling */
  metadata: MetaData;

  /** The identifier of the last user or process that updated this transaction */
  updatedBy: string;

  version: number;

  /** Timestamps */
  createdAt: number;
  updatedAt: number;
}

/** Firestore collection configuration for PaymentTransaction */
export const FBD_PaymentTransaction = MakeFBDocType<DTPaymentTransaction>({
  name: 'PaymentTransaction',
  collection: 'payment_transactions',
  empty: () => {
    const result: DTPaymentTransaction = {
      ...Empty_NoSqlDoc(),
      consumerLink:'',
      paymentIntentId: '',
      chargeId: '',
      amount: 0,
      currency: CURRENCY_TYPES.USD,
      status: TransactionStatus.PENDING,
      receiptUrl: '',
      paymentMethodId: '',
      captured: false,
      failureCode: '',
      failureMessage: '',
      transactionDate: OurDateTime.nowUTCTimestamp(),
      paymentProvider: 'stripe',
      metadata: {
        holdingLink: '',
        tenantLink: '',
        consumerLink: '',
        warrantorLink: '',
        retailerLink: '',
        templateLink: '',
        offerLink: '',
      },
      updatedBy: '',
      createdAt: OurDateTime.nowUTCTimestamp(),
      updatedAt: OurDateTime.nowUTCTimestamp(),
      version: 1
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});

/** Zod schema for TransactionStatus */
export const Z_TransactionStatus = z.nativeEnum(TransactionStatus);

/** Zod schema for PaymentTransaction validation */
export const Z_DTPaymentTransaction = schema<DTPaymentTransaction>()(
  z
    .object({
      consumerLink:Z_ConsumerLink,
      paymentIntentId: z.string(),
      chargeId: z.string().optional(),
      paymentRequestId: z.string(),
      amount: z.number(),
      currency: z.string(),
      status: Z_TransactionStatus,
      receiptUrl: z.string().optional(),
      paymentMethodId: z.string().optional(),
      captured: z.boolean().optional(),
      failureCode: z.string().optional(),
      failureMessage: z.string().optional(),
      transactionDate: z.number(),
      paymentProvider: z.literal('stripe'),
      metadata: z.object({
        holdingLink: Z_HoldingLink.optional(),
        tenantLink: Z_TenantLink.optional(),
        consumerLink: Z_ConsumerLink,
        warrantorLink: Z_WarrantorLink,
        retailerLink: Z_RetailerLink.optional(),
        serviceTemplateLink: z.string().optional(),
        serviceOfferLink: z.string().optional(),
      }),
      updatedBy: z.string(),
      createdAt: z.number(),
      updatedAt: z.number(),
    })
    .merge(Z_NoSqlDoc) // Inherit NoSqlDoc properties
);
