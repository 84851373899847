import {
  ForwardRefExoticComponent,
  SVGProps,
  useEffect,
  useState,
} from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Button } from '@rabbit/elements/shared-components';

export interface TabsGenericProps {
  items: Array<TabItemProps>;
  query?: boolean;
  hasActivityUpdate?: boolean;
  hasChatUpdate?: boolean;
  hugTabs?: boolean;
  customTabs?: boolean;
  plain?: boolean;
  autoSelect?: boolean;
}

export interface TabItemProps {
  label: string;
  icon?: ForwardRefExoticComponent<SVGProps<SVGSVGElement>>;
  children: React.ReactNode;
  disabled?: boolean;
}

export function TabsGeneric(props: TabsGenericProps) {
  const {
    items,
    query = true,
    hasActivityUpdate,
    hasChatUpdate,
    hugTabs,
    plain,
    customTabs,
    autoSelect = true,
  } = props;
  const navigate = useNavigate();

  const [activeTab, setActive] = useState<number>(autoSelect ? 0 : -1);
  const [paramValue, setParamValue] = useState<string>('');
  const [open, setOpen] = useState(false);

  const QUERY_PARAM = 'tab';
  const searchParams = new URLSearchParams(window.location.search);

  const setActiveTab = (value: string) => {
    for (let i = 0; i < items.length; i++) {
      const label = String(items[i].label).toLocaleLowerCase();
      if (value.toLocaleLowerCase() === label) {
        setActive(i);
        if (query) {
          navigate({
            search: createSearchParams({ tab: label }).toString(),
          });
        }
      }
    }
  };

  useEffect(() => {
    const currentValue = searchParams.get(QUERY_PARAM) || '';
    if (currentValue !== paramValue) {
      setParamValue(currentValue);
      setActiveTab(currentValue);
    }
  }, [window.location.search, QUERY_PARAM, paramValue]);

  return (
    <>
      <div
        className={`
          ${hugTabs || customTabs ? 'flex flex-col items-center' : ''}
          ${customTabs ? 'w-full' : ''}
          `}
      >
        {items && items.length > 1 && (
          <>
            {/* -- DESKTOP -- */}
            <div
              className={`mb-5 hidden justify-around overflow-hidden overflow-x-auto text-gray-500 md:flex lg:inline-flex lg:justify-start
                ${!hugTabs && !customTabs ? 'w-full' : ''}
                ${customTabs ? 'w-full rounded-xl' : !plain ? 'rounded-md' : ''}
                ${plain ? 'border-b border-gray-200' : 'border shadow-sm'}
                `}
            >
              {items?.map((item, index) => (
                <div
                  key={index}
                  className={`lg:px-auto flex-grow cursor-pointer border-gray-200 py-4 text-center
                    ${customTabs && activeTab !== index ? 'bg-white' : ''}
                    ${
                      activeTab === index && !customTabs && !plain
                        ? 'bg-white'
                        : 'bg-gray-100'
                    }
                    ${
                      plain && activeTab === index
                        ? 'text-primary-800 border-primary-800 border-b-2 font-bold'
                        : ''
                    }
                    ${
                      index !== items.length - 1 && !plain
                        ? 'border-r-[1px]'
                        : ''
                    }
                    ${plain ? 'border-0 border-r-0 bg-transparent' : ''}
                    ${item.disabled ? 'cursor-auto opacity-50' : ''} ${
                    customTabs && activeTab === index ? 'bg-primary-500' : ''
                  }`}
                  onClick={() => !item.disabled && setActiveTab(item.label)}
                >
                  <div
                    className={classNames(
                      'flex items-center justify-center gap-2',

                      {
                        'px-16': hugTabs,
                      },
                      `
                      ${
                        customTabs && activeTab === index
                          ? 'text-white'
                          : 'text-gray-500'
                      }
                      ${customTabs && 'text-lg'} ${!plain ? 'font-bold' : ''}
                      ${plain && activeTab === index ? 'text-primary-800' : ''}
                      `
                    )}
                  >
                    {item.label}
                    {item.label === 'Chat' && hasChatUpdate && (
                      <div className="h-[10px] w-[10px] rounded-full bg-red-500"></div>
                    )}
                    {item.label === 'Activity' && hasActivityUpdate && (
                      <div className="h-[10px] w-[10px] rounded-full bg-red-500"></div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {/* -- MOBILE -- */}
            <div className="relative z-[20] mb-4 flex w-full md:hidden">
              <div className="relative w-full rounded-lg border border-gray-300 shadow-md dark:border-gray-700">
                <Button
                  onClick={() => setOpen(!open)}
                  className={`font-nunito flex w-full items-center justify-between rounded-t-lg ${
                    open ? 'rounded-b-[0px] bg-white' : ''
                  } px-4 py-2 text-gray-900`}
                >
                  <span className="font-bold">
                    {items && items[activeTab] && items[activeTab].label}
                  </span>
                  <ChevronDownIcon
                    className={`h-5 w-5 text-gray-500 ${
                      open ? 'rotate-180' : 'rotate-0'
                    }`}
                  />
                </Button>
                {open && (
                  <ul className="font-nunito absolute w-full rounded-b-lg border-t border-gray-200 bg-white shadow-lg dark:border-gray-700 dark:bg-gray-900">
                    {items
                      .filter((_, i) => i !== activeTab)
                      .map((tab) => (
                        <li
                          key={tab.label}
                          onClick={() => {
                            setActiveTab(tab.label);
                            setOpen(false);
                          }}
                          className="cursor-pointer border-b border-gray-100 px-4 py-2"
                        >
                          {tab.label}
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            </div>
          </>
        )}
        <div className="w-full">
          {items && items[activeTab] && items[activeTab].children}
        </div>
      </div>
    </>
  );
}

export default TabsGeneric;
