const ROUTE_NAME = {
  ACCOUNT: '/account',
  ACCOUNT_ADDITIONAL_INFO: '/account/additional-info',
  ACCOUNT_ADDRESSES: '/account/addresses',
  ACCOUNT_EDIT: '/account/edit',
  ACCOUNT_MARKETING: '/account/marketing',
  ACCOUNT_PRIVACY: '/account/privacy',
  ACCOUNT_TRANSACTION: '/account/transactions',
  AUTH: '/auth',
  AUTH_ERROR: '/auth/error',
  DASHBOARD: '/',
  ONBOARDING: '/onboarding',
  COMPLETE_PROFILE: '/complete-profile',
  IMPACT: '/impact',
  PRIVACY_POLICY: '/privacy-policy',
  PRODUCTS: '/products',
  PRODUCTS_SEARCH: '/products/search',
  PRODUCTS_SCAN: '/products/scan',
  PRODUCTS_ADD: '/products/add',
  PRODUCTS_PREVIEW: '/products/preview',
  REPAIRS: '/repairs',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  NON_LOGGED_START_CLAIM: '/initiate-claim',
  NON_LOGGED_CONTINUE_CLAIM: '/continue-claim',
  LANDING_REGISTER_PRODUCT: '/landing/register-product',
  LANDING_NEW_PRODUCT: '/landing/new-product',
  SERVICE_REQUEST: '/service-request',
  WELCOME: '/welcome',
  ARTICLE_COVER_OUTDOOR_FURNITURE:
    '/article/why-should-you-cover-your-outdoor-furniture',
  ARTICLE_BEST_MATERIALS_OUTDOOR:
    '/article/what-materials-are-best-for-outdoor-furniture',
  ARTICLE_REVAMP_OUTDOOR_SPACE: '/article/revamp-your-outdoor-space',
  ARTICLE_REVAMP_OUTDOOR_PATIO: '/article/revamp-your-outdoor-patio',
  ARTICLE_PROTECT_FROM_SUN: '/article/protect-your-outdoor-furniture',
  ARTICLE_PROTECT_FROM_RAIN: '/article/rainproofing-your-outdoor-oasis',
  ARTICLE_FURNITURE_LONGEVITY: '/article/preserve-protect-prolong',
  ARTICLE_ANALYSE_GARDEN_SURFACE: '/article/how-analyse-your-garden-surface',
  REGISTER_PRODUCT_INSTALLER: '/insreg-product',
};
export default ROUTE_NAME;
